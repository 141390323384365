import React, { useState } from 'react';
import { faArrowLeft, faLink, faCopy } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import QRCode from 'qrcode.react'; // Assuming you are using 'qrcode.react' for QR code generation

const ReferralProgram = () => {
    const [activeTab, setActiveTab] = useState('referral');

    return (
        <div className="referral-program">
            <div className="row header py-2">
                <button className="back-button col-lg-4 col-3">
                <a className='nav-link' href='/account'>
            <FontAwesomeIcon icon={faArrowLeft} />
          </a>
                </button>
                <h6 className='col-lg-4 col-6'>Referral Program</h6>
            </div>
            <div className="tabs">
                <button
                    className={`tab ${activeTab === 'info' ? 'active' : ''}`}
                    onClick={() => setActiveTab('info')}
                >
                    Referral Info
                </button>
                <button
                    className={`tab ${activeTab === 'referral' ? 'active' : ''}`}
                    onClick={() => setActiveTab('referral')}
                >
                    My Referral
                </button>
            </div>
            <div className="tab-content">
                {activeTab === 'info' ? (
                    <div className="info-content">
                      
                    </div>
                ) : (
                    <div className="referral-content">
                        <div className='ref-content-header'>
                            <img src='./logos/logo.png' className='ref-logo' alt=''/>
                          <span className='ref-title'> REFER YOUR FRIENDS </span>
                          <span className='ref-title text-warning'>  AND EARN  </span>
                          
                        </div>
                        <div className='ref-content m-3 '>
                           <div className='ref-content-qr'>
                           <p >Your Referral Code</p>
                            <h3 className='text-warning my-3'>5B01tv <FontAwesomeIcon icon={faCopy} /></h3>
                            <QRCode value="5B01tv" size={128} />
                            <div className='qr-action my-3'>
                              <button>Copy Link <FontAwesomeIcon icon={faLink} /></button>
                              <button>Share</button>
                            </div>
                           </div>
                        </div>
                        
                    </div>
                )}
            </div>
        </div>
    );
}

export default ReferralProgram;
