import React from "react";

function FuturesGames(){
    return(
        <div className="favourite-games content-title my-1">
            <h2>Featured Games</h2>
            <div className="favourite-games-items featured-game">
                <ul>
                    <li>
                    <img src='./sliders/117.png' alt="Cricket Match" className="slide-image" />
                    <p className="text-white">Super Ace</p>
                    </li>
                    <li>
                    <img src='./sliders/14080.png' alt="Cricket Match" className="slide-image" />
                    <p className="text-white">Money Coming</p>

                    </li>
                    <li>
                    <img src='./sliders/JILI-SLOT-027.png' alt="Cricket Match" className="slide-image" />
                    <p className="text-white">Cocktail Nights</p>

                    </li>
                    <li>
                    <img src='./sliders/JILI-SLOT-029.png' alt="Cricket Match" className="slide-image" />
                    <p className="text-white">Super Ace</p>

                    </li>
                    <li>
                    <img src='./sliders/JILI-SLOT-076.png' alt="Cricket Match" className="slide-image" />
                    <p className="text-white">Cocktail Nights</p>

                    </li>
                    <li>
                    <img src='./sliders/117.png' alt="Cricket Match" className="slide-image" />
                    <p className="text-white">Super Ace</p>
                    </li>
                </ul>
            </div>
        </div>
    );

}
export default FuturesGames;