import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {  faRightFromBracket, faXmark} from '@fortawesome/free-solid-svg-icons';

function Account(){
    
    const [userData, setUserData] = useState(null);
    useEffect(() => {
        const fetchUserData = async () => {
            const token = localStorage.getItem('token');
            const remoteId = localStorage.getItem('remote_id');
            
            if (token && remoteId) {
                try {
                    const response = await fetch(`https://nofa365.com/api/user/show?remote_id=${remoteId}`, {
                        method: 'GET',
                        headers: {
                            'Authorization': `Bearer ${token}`,
                        },
                    });
    
                    if (response.ok) {
                        const data = await response.json();
                        
                        setUserData(data);
                        
                    } else {
                        console.error('Failed to fetch user data with status:', response.status);
                        // Optionally, read and log the response text to see the error page content
                        const text = await response.text();
                        console.error('Error page content:', text);
                    }
                } catch (error) {
                    console.error('Error fetching user data:', error);
                }
            }
        };
    
        fetchUserData();
    }, []);

    const logout = () => {
        localStorage.removeItem('token');
        localStorage.removeItem('sessionid');
        localStorage.removeItem('remote_id');
        localStorage.removeItem('username');
        window.location.href = '/';
      };
    return(
        <div className="account">
            <div className="account-header">
                <div className="close">
                    <a href="/"> <FontAwesomeIcon icon={faXmark} className="px-3 icon-close" /> </a>       
                </div>
                <div className="account-info">
                    <div className="grading-icon">
                        <img src="./icon/normal.png" alt=""/>
                    </div>
                    <div>
                    {userData ? (
                           <h4 className="text-light text-start">{userData.username}</h4>
                        ) : (
                         <p>Loading user data...</p>
                     )}
                    
                    <div className="account-vip">
                        <p className="text-light py-1 px-3">VIP Poker VP | My VIP </p>
                    </div>
                    </div>

                </div>
            </div>
            <div className="account-body">
                <div  className="card">
                    <div className="card-body">
                        <p>Main Balance</p>
                        {userData ? (
                           
                           <p className="text-warning">৳ {userData.balance}</p>
                        ) : (
                         <p>Loading user data...</p>
                     )}
                        
                    </div>
                </div>
                <div  className="card">
                <div className="card-title">
                           <div className="card-title-l-line"></div> 
                           <h4>Funds</h4>
                        </div>
                    <div className="card-body">
                       <div className="account-item">
                      <a href="/fund"> <img src="./icon/icon-deposit.png" alt="" className="item-icon"/>
                      <p className="text-light">Deposit</p></a> 
                       </div>
                       <div className="account-item">
                       <a href="/fund">
                        <img src="./icon/icon-withdrawal.png" alt="" className="item-icon"/>
                            <p className="text-light">withdrawal</p>
                            </a>
                       </div>
                    </div>
                </div>
                <div  className="card">
                <div className="card-title">
                           <div className="card-title-l-line"></div> 
                           <h4>History</h4>
                        </div>
                    <div className="card-body">
                       <div className="account-item">
                       <a href="/betting-record">
                        <img src="./icon/icon-bet-records.png" alt="" className="item-icon"/>
                            <p className="text-light">Betting Records</p>
                        </a>
                       </div>
                       <div className="account-item">
                       <a href="/transaction-record">
                        <img src="./icon/icon-turnover.png" alt="" className="item-icon"/>
                            <p className="text-light">Turnover</p>
                            </a>
                       </div>
                       <div className="account-item">
                       <a href="/transaction-record">
                        <img src="./icon/icon-records.png" alt="" className="item-icon"/>
                            <p className="text-light">Transaction Records</p>
                            </a>
                       </div>
                    </div>
                </div>
                <div  className="card">
                <div className="card-title">
                           <div className="card-title-l-line"></div> 
                           <h4>Profile</h4>
                        </div>
                    <div className="card-body">
                       <div className="account-item">
                       <a href="/profile">
                        <img src="./icon/icon-profile.png" alt="" className="item-icon"/>
                            <p className="text-light">Personal Info</p>
                            </a>
                       </div>
                       <div className="account-item">
                       <a href="/password-reset">
                        <img src="./icon/icon-withdrawal.png" alt="" className="item-icon"/>
                            <p className="text-light">Password Reset</p>
                            </a>
                       </div>
                       <div className="account-item">
                        <img src="./icon/icon-inbox.png" alt="" className="item-icon"/>
                            <p className="text-light">Inbox</p>
                       </div>
                       <div className="account-item">
                        <a href='/referral-program'>
                        <img src="./icon/icon-referral.png" alt="" className="item-icon"/>
                            <p className="text-light">Referral</p>
                            </a>
                       </div>
                    </div>
                </div>
                <div  className="card">
                <div className="card-title">
                           <div className="card-title-l-line"></div> 
                           <h4>Contract Us</h4>
                        </div>
                    <div className="card-body">
                       <div className="account-item">
                        <img src="./icon/icon-whatsapp.png" alt="" className="item-icon"/>
                            <p className="text-light">Whats App</p>
                       </div>
                       <div className="account-item">
                        <img src="./icon/icon-email.png" alt="" className="item-icon"/>
                            <p className="text-light">Email</p>
                       </div>
                       <div className="account-item">
                        <img src="./icon/icon-whatsapp.png" alt="" className="item-icon"/>
                            <p className="text-light">Facebook</p>
                       </div>
                    </div>
                </div>
                <div  className="card">
                
                   <li onClick={logout} className="text-light"> <FontAwesomeIcon icon={faRightFromBracket} className="px-3" /> LogOut</li>
                </div>
            </div>
            <div className="account-footer"></div>

        </div>
    );
}
export default Account;