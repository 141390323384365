import React, { useState } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleLeft, faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';

const Login = () => {
    const [formData, setFormData] = useState({
        username: '',
        password: '',
    });

    const [errors, setErrors] = useState({});
    const [passwordShown, setPasswordShown] = useState(false);

    const togglePasswordVisibility = () => {
        setPasswordShown(!passwordShown);
    };

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const validate = () => {
        let tempErrors = {};
        const usernamePattern = /^[a-zA-Z0-9]+$/;

        // Username validation
        if (!formData.username) tempErrors.username = "Username is required";
        else if (!usernamePattern.test(formData.username)) tempErrors.username = "Invalid username (only letters and numbers)";

        // Password validation
        if (!formData.password) tempErrors.password = "Password is required";
        else if (formData.password.length < 8) tempErrors.password = "Password must be at least 8 characters";

        setErrors(tempErrors);
        return Object.keys(tempErrors).length === 0;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (validate()) {
            await handleLogin(formData);
        } else {
            console.log("Form has errors. Please fix them and try again.");
        }
    };

    const handleLogin = async ({ username, password }) => {
        try {
            const response = await fetch('https://nofa365.com/api/login', {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ username, password }),
            });

            const data = await response.json();

            if (response.ok) {
                console.log(data);
                localStorage.setItem('token', data.token);
                localStorage.setItem('sessionid', data.response.response.sessionid);
                localStorage.setItem('remote_id', data.response.response.id);
                localStorage.setItem('username', data.response.response.username);

                // Redirect to another page or update the state to indicate successful login
                window.location.href = '/'; // Redirect to a dashboard page
            } else {
                console.log(data.message || 'An unexpected error occurred');  // Assuming the server sends an error message in the response
            }
        } catch (error) {
            console.error('Error during login:', error);
        }
    };

    return (
        <div className="register">
            <div className="register-header text-white">
                <a className="nav-link" href="/"><FontAwesomeIcon icon={faAngleLeft} className="px-3" /></a>
                <h6>SIGN IN</h6>
                <span></span>
            </div>
            <div className="register-body">
                <div className="signup-banner">
                    <img src="./bg/en-sponsor.jpg" className="w-100" alt="" />
                </div>

                <div className="p-3">
                    <form onSubmit={handleSubmit}>
                        <div className="card">
                            <div className="bottom-input-group">
                                <label>Username</label>
                                <div className="input-field-group">
                                    <input
                                        name="username"
                                        type="text"
                                        placeholder="Username"
                                        value={formData.username}
                                        onChange={handleChange}
                                    />
                                </div>
                            </div>
                            {errors.username && <span className="validation-error text-danger">{errors.username}</span>}
                            <div className="bottom-input-group">
                                <label>Password</label>
                                <div className="input-field-group">
                                    <input
                                        name="password"
                                        type={passwordShown ? "text" : "password"}
                                        placeholder="Password"
                                        value={formData.password}
                                        onChange={handleChange}
                                    />
                                    <FontAwesomeIcon
                                        icon={passwordShown ? faEyeSlash : faEye}
                                        onClick={togglePasswordVisibility}
                                        className="text-white password-toggle-icon"
                                    />
                                </div>
                            </div>
                            {errors.password && <span className="validation-error text-danger">{errors.password}</span>}
                        </div>
                        <button type="submit" className="btn">Login</button>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default Login;
