import React from "react";
import CasinoBanner from "../Banner/CasinoBanner";
import PokerList from "../common/PokerList";


function Poker(){
   return(
    <div className="casino">
      <br/>
      <br/>
    <CasinoBanner/>
    <PokerList/>
    </div>
   );
}
export default Poker;