import React from "react";
import MainSlider from "../Sliders/MainSlider";
import FavouriteSlider from "../Sliders/FavouriteSlider";
import MobileNav from "../NavBar/MobileNav";
import MoblileFavouriteSlide from "../Sliders/MoblileFavouriteSlide";
import FuturesGames from "../Sliders/FuturesGames";
import LiveChat from "../modals/LiveChat";


function Home(){
    return(
        <div className="max-width">
            <div className="contanier">
               
                <MainSlider/>

                <MobileNav/>
                <div className="mobile-d-none">
                <FavouriteSlider/>
                
                </div>
                <MoblileFavouriteSlide/>
                <FuturesGames/>
                <LiveChat/>
                <br/>
                <br/>
               
                
            </div>
        </div>
    );

}
export default Home;