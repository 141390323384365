import React, { useState } from "react";
import { useSwipeable } from "react-swipeable";

const sections = [
    
    { title: "Sports", icon: "./icon/icon-sport.png" },
    { title: "Casino", icon: "./icon/icon-casino.png" },
    { title: "Slot", icon: "./icon/icon-slot.png" },
    { title: "Table", icon: "./icon/icon-table.png" },
    { title: "Poker", icon: "./icon/icon-arcade.png" },
    { title: "Crash", icon: "./icon/icon-crash.png" },
    { title: "Fishing", icon: "./icon/icon-fish.png" },
    { title: "Lottery", icon: "./icon/icon-lottery.png" }
];

function MobileNav() {
    const [activeIndex, setActiveIndex] = useState(0);

    const handleSwipe = (direction) => {
        if (direction === "left" && activeIndex < sections.length - 1) {
            setActiveIndex(activeIndex + 1);
        } else if (direction === "right" && activeIndex > 0) {
            setActiveIndex(activeIndex - 1);
        }
    };

    const handlers = useSwipeable({
        onSwipedLeft: () => handleSwipe("left"),
        onSwipedRight: () => handleSwipe("right"),
        preventDefaultTouchmoveEvent: true,
        trackMouse: true,
    });

    return (
        <>
            <div className="mobile-nav">
                <ul>
                <li className="nav-item home-nav"> 
                <a href="/">
                    <div>
                          
                          <img src="./icon/icon-home.png" alt=""/> 
                          <p>Home</p>
                          
                    </div>
                    </a>
                        </li>
                    {sections.map((section, index) => (
                        <li
                            key={section.title}
                            className={`nav-item ${activeIndex === index ? "active" : ""}`}
                            onClick={() => setActiveIndex(index)}
                        >
                            <div>
                                <img src={section.icon} alt={section.title} />
                                <p>{section.title}</p>
                            </div>
                        </li>
                    ))}
                    
                <div className="menu-spare my-2">
                <li className="nav-item home-nav"> 
                <a href="/">
                    <div>
                          
                          <img src="./icon/icon-home.png" alt=""/> 
                          <p>Promotion</p>
                          
                    </div>
                    </a>
                        </li>
                        <li className="nav-item home-nav"> 
                <a href="/">
                    <div>
                          
                          <img src="./icon/icon-home.png" alt=""/> 
                          <p> Referral Program</p>
                          
                    </div>
                    </a>
                        </li>
                        <li className="nav-item home-nav"> 
                <a href="/">
                    <div>
                          
                          <img src="./icon/icon-home.png" alt=""/> 
                          <p> VIP </p>
                          
                    </div>
                    </a>

                        </li>
                </div>

                <div className="card">
                    <div className="tap-bar-noice">
                        
                    </div>
                </div>
                
                </ul>
            </div>

            <div className="menu-overview content-title">
                <h2>{sections[activeIndex].title}</h2>
                <div className="menu-overview-list active" {...handlers}>
                    {activeIndex === 0 && (
                        <>
                            <div className="menu-overview-list-item">
                            <a href="/sports">
                                <img src="./icon/icon-exchange.svg" className="sports-icon-menu" alt="Exchange" />
                                <p>Exchange</p>
                                </a>
                            </div>
                            <div className="menu-overview-list-item">
                            <a href="/sports">
                                <img src="./icon/icon-sportbook.svg" className="sports-icon-menu" alt="iSports" />
                                <p>iSports</p>
                                </a>
                            </div>
                            <div className="menu-overview-list-item">
                            <a href="/sports">
                                <img src="./icon/icon-sbov2.svg" className="sports-icon-menu" alt="SBO Sports" />
                                <p>SBO Sports</p>
                                </a>
                            </div>
                            <div className="menu-overview-list-item">
                            <a href="/sports">
                                <img src="./icon/icon-awcme1sport.svg" className="sports-icon-menu" alt="E1SPORTS" />
                                <p>E1SPORTS</p>
                                </a>
                            </div>
                            <div className="menu-overview-list-item">
                            <a href="/sports">
                                <img src="./icon/icon-horsebook.svg" className="sports-icon-menu" alt="Hoursbook" />
                                <p>Hoursbook</p>
                                </a>
                            </div>
                            <div className="menu-overview-list-item">
                            <a href="/sports">
                                <img src="./icon/icon-virtual.svg" className="sports-icon-menu" alt="Virtual" />
                                <p>Virtual</p>
                                </a>
                            </div>
                            <div className="menu-overview-list-item">
                                <img src="./icon/icon-kabaddi.svg" className="sports-icon-menu" alt="Kabaddu" />
                                <p>Kabaddu</p>
                            </div>
                            <div className="menu-overview-list-item">
                                <img src="./icon/icon-powerelevenv2.svg" className="sports-icon-menu" alt="FANSASY" />
                                <p>FANSASY</p>
                            </div>
                        </>
                    )}
                    {activeIndex === 1 && (
                        <>
                            <div className="menu-overview-list-item">
                                <a href="/casino">
                                <img src="./icon/provider-evo.png" alt="Evolation" />
                                <p>Evolation</p>
                                </a>
                            </div>
                            <div className="menu-overview-list-item">
                            <a href="/casino">
                                <img src="./icon/provider-awcmsexy.png" alt="Sexy" />
                                <p>Sexy</p>
                                </a>
                            </div>
                            <div className="menu-overview-list-item">
                            <a href="/casino">
                                <img src="./icon/provider-awcmpp.png" alt="SPragmatic Play" />
                                <p>SPragmatic Play</p>
                                </a>
                            </div>
                            <div className="menu-overview-list-item">
                            <a href="/casino">
                                <img src="./icon/provider-awcmpt.png" alt="Playtech" />
                                <p>Playtech</p>
                                </a>
                            </div>
                            <div className="menu-overview-list-item">
                            <a href="/casino">
                                <img src="./icon/provider-awcmhotroad.png" alt="HotRoad" />
                                <p>HotRoad</p>
                                </a>
                            </div>
                            <div className="menu-overview-list-item">
                              
                            </div>
                            <div className="menu-overview-list-item">
                               
                            </div>
                            <div className="menu-overview-list-item">
                               
                            </div>
                        </>
                    )}
                    {activeIndex === 2 && (
                        <>
                            <div className="menu-overview-list-item">
                            <a href="/slot">
                                <img src="./icon/provider-awcmjili.png" alt="Evolation" />
                                <p>Jili</p>
                                </a>
                            </div>
                            <div className="menu-overview-list-item">
                            <a href="/slot">
                                <img src="./icon/provider-pg.png" alt="Sexy" />
                                <p>PG Slot</p>
                                </a>
                            </div>
                            <div className="menu-overview-list-item">
                            <a href="/slot">
                                <img src="./icon/provider-jdb.png" alt="SPragmatic Play" />
                                <p>JDB</p>
                                </a>
                            </div>
                            <div className="menu-overview-list-item">
                            <a href="/slot">
                                <img src="./icon/provider-awcmfc.png" alt="Playtech" />
                                <p>Fa Chai</p>
                                </a>
                            </div>
                            <div className="menu-overview-list-item">
                            <a href="/slot">
                                <img src="./icon/provider-awcmsg.png" alt="HotRoad" />
                                <p>Spadegaming </p>
                                </a>
                            </div>
                            <div className="menu-overview-list-item">
                            <a href="/slot">
                            <img src="./icon/provider-awcmrt.png" alt="HotRoad" />
                            <p>Red Tiger </p>
                            </a>
                              
                            </div>
                            <div className="menu-overview-list-item">
                            <a href="/slot">
                            <img src="./icon/provider-awcmfastspin.png" alt="HotRoad" />
                            <p>FastsPain </p>
                            </a>
                            </div>
                            <div className="menu-overview-list-item">
                            <a href="/slot">
                            <img src="./icon/provider-awcmsexy.png" alt="Sexy" />
                            <p>Playtech</p>
                            </a>
                            </div>
                        </>
                    )}
                    {activeIndex === 3 && (
                        <>
                            <div className="menu-overview-list-item">
                            <a href="/table">
                                <img src="./icon/provider-awcmjili.png" alt="Evolation" />
                                <p>Jili</p>
                                </a>
                            </div>
                            <div className="menu-overview-list-item">
                            <a href="/table">
                                <img src="./icon/provider-pg.png" alt="Sexy" />
                                <p>PG Slot</p>
                                </a>
                            </div>
                            <div className="menu-overview-list-item">
                            <a href="/table">
                                <img src="./icon/provider-jdb.png" alt="SPragmatic Play" />
                                <p>JDB</p>
                                </a>
                            </div>
                            <div className="menu-overview-list-item">
                            <a href="/table">
                                <img src="./icon/provider-awcmfc.png" alt="Playtech" />
                                <p>Fa Chai</p>
                                </a>
                            </div>
                            <div className="menu-overview-list-item">
                            <a href="/table">
                                <img src="./icon/provider-awcmsg.png" alt="HotRoad" />
                                <p>Spadegaming </p>
                                </a>
                            </div>
                            <div className="menu-overview-list-item">
                            <img src="./icon/provider-awcmrt.png" alt="HotRoad" />
                            <p>Red Tiger </p>
                              
                            </div>
                            <div className="menu-overview-list-item">
                            <img src="./icon/provider-awcmfastspin.png" alt="HotRoad" />
                            <p>FastsPain </p>
                            </div>
                            <div className="menu-overview-list-item">
                            <img src="./icon/provider-awcmsexy.png" alt="Sexy" />
                            <p>Playtech</p>
                            </div>
                            <div className="menu-overview-list-item">
                                <img src="./icon/provider-awcmjili.png" alt="Evolation" />
                                <p>Jili</p>
                            </div>
                            <div className="menu-overview-list-item">
                                <img src="./icon/provider-pg.png" alt="Sexy" />
                                <p>PG Slot</p>
                            </div>
                            <div className="menu-overview-list-item">
                                <img src="./icon/provider-jdb.png" alt="SPragmatic Play" />
                                <p>JDB</p>
                            </div>
                            <div className="menu-overview-list-item">
                                <img src="./icon/provider-awcmfc.png" alt="Playtech" />
                                <p>Fa Chai</p>
                            </div>
                        </>
                    )}
                    {activeIndex === 4 && (
                        <>
                         <div className="menu-overview-list-item">
                         <a href="/poker">
                                <img src="./icon/provider-awcmsg.png" alt="HotRoad" />
                                <p>Spadegaming </p>
                                </a>
                            </div>
                            <div className="menu-overview-list-item">
                            <a href="/poker">
                            <img src="./icon/provider-awcmrt.png" alt="HotRoad" />
                            <p>Red Tiger </p>
                            </a>
                              
                            </div>
                            <div className="menu-overview-list-item">
                        <a href="/poker">
                            <img src="./icon/provider-awcmfastspin.png" alt="HotRoad" />
                            <p>FastsPain </p>
                            </a>
                            </div>
                            <div className="menu-overview-list-item">
                            <a href="/poker">
                            <img src="./icon/provider-awcmsexy.png" alt="Sexy" />
                            <p>Playtech</p>
                            </a>
                            </div>
                            <div className="menu-overview-list-item">
                            <a href="/poker">
                                <img src="./icon/provider-awcmjili.png" alt="Evolation" />
                                <p>Jili</p>
                                </a>
                            </div>
                            <div className="menu-overview-list-item">

                                <img src="./icon/provider-pg.png" alt="Sexy" />
                                <p>PG Slot</p>
                            </div>
                            <div className="menu-overview-list-item">
                                <img src="./icon/provider-jdb.png" alt="SPragmatic Play" />
                                <p>JDB</p>
                            </div>
                            <div className="menu-overview-list-item">
                                <img src="./icon/provider-awcmfc.png" alt="Playtech" />
                                <p>Fa Chai</p>
                            </div>
                            <div className="menu-overview-list-item">
                                <img src="./icon/provider-awcmsg.png" alt="HotRoad" />
                                <p>Spadegaming </p>
                            </div>
                            <div className="menu-overview-list-item">
                            <img src="./icon/provider-awcmrt.png" alt="HotRoad" />
                            <p>Red Tiger </p>
                              
                            </div>
                            <div className="menu-overview-list-item">
                            <img src="./icon/provider-awcmfastspin.png" alt="HotRoad" />
                            <p>FastsPain </p>
                            </div>
                            <div className="menu-overview-list-item">
                            <img src="./icon/provider-awcmsexy.png" alt="Sexy" />
                            <p>Playtech</p>
                            </div>
                        </>
                    )}
                    {activeIndex === 5 && (
                        <>

                            <div className="menu-overview-list-item">
                                <a href="/crash">
                                <img src="./icon/provider-awcmjili.png" alt="Evolation" />
                                <p>Jili</p>
                                </a>
                            </div>
                            <div className="menu-overview-list-item">
                            <a href="/crash">
                                <img src="./icon/provider-pg.png" alt="Sexy" />
                                <p>PG Slot</p>
                                </a>
                            </div>
                            <div className="menu-overview-list-item">
                                <img src="./icon/provider-jdb.png" alt="SPragmatic Play" />
                                <p>JDB</p>
                            </div>
                            <div className="menu-overview-list-item">
                                <img src="./icon/provider-awcmfc.png" alt="Playtech" />
                                <p>Fa Chai</p>
                            </div>
                            <div className="menu-overview-list-item">
                                <img src="./icon/provider-awcmsg.png" alt="HotRoad" />
                                <p>Spadegaming </p>
                            </div>
                            <div className="menu-overview-list-item">
                            <img src="./icon/provider-awcmrt.png" alt="HotRoad" />
                            <p>Red Tiger </p>
                              
                            </div>
                            <div className="menu-overview-list-item">
                            <img src="./icon/provider-awcmfastspin.png" alt="HotRoad" />
                            <p>FastsPain </p>
                            </div>
                            <div className="menu-overview-list-item">
                            
                            </div>
                        </>
                    )}
                    {activeIndex === 6 && (
                        <>
                            <div className="menu-overview-list-item">
                                <img src="./icon/provider-awcmjili.png" alt="Evolation" />
                                <p>Jili</p>
                            </div>
                            <div className="menu-overview-list-item">
                                <img src="./icon/provider-pg.png" alt="Sexy" />
                                <p>PG Slot</p>
                            </div>
                            <div className="menu-overview-list-item">
                                <img src="./icon/provider-jdb.png" alt="SPragmatic Play" />
                                <p>JDB</p>
                            </div>
                            <div className="menu-overview-list-item">
                                <img src="./icon/provider-awcmfc.png" alt="Playtech" />
                                <p>Fa Chai</p>
                            </div>
                            <div className="menu-overview-list-item">
                                <img src="./icon/provider-awcmsg.png" alt="HotRoad" />
                                <p>Spadegaming </p>
                            </div>
                            <div className="menu-overview-list-item">
                            <img src="./icon/provider-awcmrt.png" alt="HotRoad" />
                            <p>Red Tiger </p>
                              
                            </div>
                            <div className="menu-overview-list-item">
                            <img src="./icon/provider-awcmfastspin.png" alt="HotRoad" />
                            <p>FastsPain </p>
                            </div>
                            <div className="menu-overview-list-item">
                            <img src="./icon/provider-awcmsexy.png" alt="Sexy" />
                            <p>Playtech</p>
                            </div>
                        </>
                    )}
                    {activeIndex === 7 && (
                        <>
                            <div className="menu-overview-list-item">
                                <img src="./icon/provider-awcmjili.png" alt="Evolation" />
                                <p>Jili</p>
                            </div>
                            <div className="menu-overview-list-item">
                                <img src="./icon/provider-pg.png" alt="Sexy" />
                                <p>PG Slot</p>
                            </div>
                            <div className="menu-overview-list-item">
                                <img src="./icon/provider-jdb.png" alt="SPragmatic Play" />
                                <p>JDB</p>
                            </div>
                            <div className="menu-overview-list-item">
                                <img src="./icon/provider-awcmfc.png" alt="Playtech" />
                                <p>Fa Chai</p>
                            </div>
                            <div className="menu-overview-list-item">
                                <img src="./icon/provider-awcmsg.png" alt="HotRoad" />
                                <p>Spadegaming </p>
                            </div>
                            <div className="menu-overview-list-item">
                            <img src="./icon/provider-awcmrt.png" alt="HotRoad" />
                            <p>Red Tiger </p>
                              
                            </div>
                            <div className="menu-overview-list-item">
                            <img src="./icon/provider-awcmfastspin.png" alt="HotRoad" />
                            <p>FastsPain </p>
                            </div>
                            <div className="menu-overview-list-item">
                            <img src="./icon/provider-awcmsexy.png" alt="Sexy" />
                            <p>Playtech</p>
                            </div>
                        </>
                    )}
                    {/* Add other sections for Slot, Table, Poker, etc. in a similar manner */}
                </div>
            </div>
        </>
    );
}

export default MobileNav;
