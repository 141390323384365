import React from 'react';
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";


function MainSlider() {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 1000,
    arrows: false,
    centerMode: true,
    centerPadding: '0px', // no padding around the center slide by default
    customPaging: function(i) {
      return <div className="custom-dot"></div>;
    },
    responsive: [
      {
        breakpoint: 768, // adjust as per your requirement
        settings: {
          slidesToShow: 1,
          centerPadding: '30px', // show part of the next and previous slides
        },
      },
    ],
  };

  return (
    <div className="main-slider">
      <Slider {...settings}>
        <div>
          <img src='./sliders/image_113449.jpg' alt='' />
        </div>
        <div>
          <img src='./sliders/image_129594.jpg' alt='' />
        </div>
        <div>
          <img src='./sliders/image_130389.jpg' alt='' />
        </div>
        <div>
          <img src='./sliders/image_130389.jpg' alt='' />
        </div>
      </Slider>

      <div className='row announcement justify-content-center'>
        <div className='col-2 announcement-icon'>
          <img src='./icon/announcement-icon.svg' alt='announcement'/>
        </div>
        <div className='col-10 announcement-text-wrapper'>
          <p className='announcement-text'>
            Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,
          </p>
        </div>
      </div>
    </div>
  );
}

export default MainSlider;
