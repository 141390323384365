import React from "react";
import CasinoBanner from "../Banner/CasinoBanner";
import SlotList from "../common/SlotList";


function Slot(){
   return(
    <div className="casino">
      <br/>
      <br/>
    <CasinoBanner/>
        <SlotList/>
    </div>
   );
}
export default Slot;