import React from "react";
import CasinoBanner from "../Banner/CasinoBanner";
import VideoPokerList from "../common/VideoPokerList";


function VideoPoker(){
   return(
    <div className="casino">
      <br/>
      <br/>
    <CasinoBanner/>
        <VideoPokerList/>
    </div>
   );
}
export default VideoPoker;