import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faMessage } from '@fortawesome/free-solid-svg-icons';
import MainBalance from '../common/MainBalance';

function Funds() {
  const [activeItem, setActiveItem] = useState({ name: 'Bkash', id: 1 });  // Initial default payment method
  const [activeAmount, setActiveAmount] = useState(null);
  const [activeTab, setActiveTab] = useState('Deposit');
  const [items, setItems] = useState([]);

  const amounts = ['100', '500', '1,000', '2,000'];

  // Fetch payment methods on component mount
  useEffect(() => {
    fetch('https://nofa365.com/api/payment-methods')
      .then(response => response.json())
      .then(data => {
        setItems(data);
      })
      .catch(error => {
        console.error('Error fetching payment methods:', error);
      });
  }, []);

  // Handles selecting a payment method
  const handleItemClick = (name, id) => {
    setActiveItem({ name, id });
  };

  // Handles selecting an amount
  const handleAmountClick = (amount) => {
    const numericAmount = parseInt(amount.replace(/,/g, ''), 10); // Convert to number
    setActiveAmount(prevAmount => {
      if (prevAmount !== null) {
        const numericPrevAmount = parseInt(prevAmount.replace(/,/g, ''), 10); // Convert previous to number
        return (numericPrevAmount + numericAmount).toLocaleString(); // Add and format
      } else {
        return numericAmount.toLocaleString(); // Format the clicked amount
      }
    });
  };

  // Handles switching between Deposit and Withdraw tabs
  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  // Submits the GET request with query parameters
  const handleSubmit = () => {
    const remoteid = localStorage.getItem('remote_id');
    const transactiontype = 'personal'; // 'deposit' or 'withdraw'
    const amount = activeAmount ? activeAmount.replace(/,/g, '') : null; // Remove commas for numerical value
  
    // Ensure all necessary data is available
    if (remoteid && activeItem && amount) {
      const url = new URL('https://nofa365.com/payment-request');
      url.searchParams.append('remoteid', remoteid);
      url.searchParams.append('paymentmethodid', activeItem.id);
      url.searchParams.append('transactiontype', transactiontype);
      url.searchParams.append('amount', amount);
      url.searchParams.append('requesttype', activeTab);
  
      // Open the URL in a new tab
      window.open(url.toString(), '_blank');
    } else {
      console.error('Missing data: remoteid, activeItem, or amount');
    }
  };
  

  return (
    <div className="funds">
      <div className="fund-header">
        <div className="fund-action-bar">
          <a className='nav-link' href='/account'>
            <FontAwesomeIcon icon={faArrowLeft} />
          </a>
          <h6>Funds</h6>
          <div className="">
            <FontAwesomeIcon icon={faMessage} />
            <p>Help</p>
          </div>
        </div>
        <div className="fund-nav">
          <ul className='px-3 py-3 mx-3'>
            <li
              className={`nav-item ${activeTab === 'Deposit' ? 'active' : ''}`}
              onClick={() => handleTabClick('Deposit')}
            >
              Deposit
            </li>
            <li
              className={`nav-item ${activeTab === 'Withdraw' ? 'active' : ''}`}
              onClick={() => handleTabClick('Withdraw')}
            >
              Withdraw
            </li>
          </ul>
        </div>
      </div>
      <MainBalance />
      {/* Deposit Tab */}
      <div className={`fund-body ${activeTab.toLowerCase()} ${activeTab === 'Deposit' ? 'open' : ''}`}>
        <div className="card">
          <div className="card-title">
            <div className="card-title-l-line"></div>
            <h4>Payment Method</h4>
          </div>
          <div className="card-body">
            {items.map((item) => (
              // Render only active payment methods
              item.status === 'active' && (
                <div
                  key={item.id}
                  className={`account-item ${activeItem.name === item.name ? 'active' : ''}`}
                  onClick={() => handleItemClick(item.name, item.id)}
                >
                  <img
                    src={`https://nofa365.com/${item.icon}`}
                    alt={item.name}
                    className={`item-icon ${item.name === 'Bank' ? 'bank' : ''}`}
                  />
                  <p className="text-light">{item.name}</p>
                </div>
              )
            ))}
          </div>
        </div>

        <div className="card">
          <div className="card-title">
            <div className="card-title-l-line"></div>
            <h4>Deposit Channel</h4>
          </div>
          <div className="card-body">
            <div className="account-item active">
              <p className="text-light">Cash Out </p>
            </div>
          </div>
        </div>

        <div className="card">
          <div className="card-title">
            <div className="card-title-l-line"></div>
            <h4>Amount</h4>
          </div>
          <div className="card-body">
            {amounts.map((amount) => (
              <div
                key={amount}
                className={`account-item ${activeAmount === amount ? 'active' : ''}`}
                onClick={() => handleAmountClick(amount)}
              >
                <p className="text-light">{amount}</p>
              </div>
            ))}
          </div>
          <div className='amount-selected'>
            <span className='text-success'>৳</span>
            <p className='px-2'>{activeAmount}</p>
          </div>
        </div>
        <button className='btn' onClick={handleSubmit}>Submit</button>
      </div>

      {/* Withdraw Tab */}
      <div className={`fund-body ${activeTab.toLowerCase()} ${activeTab === 'Withdraw' ? 'open' : ''}`}>
        <div className="card">
          <div className="card-title">
            <div className="card-title-l-line"></div>
            <h4>Payment Method</h4>
          </div>
          <div className="card-body">
            {items.map((item) => (
              // Render only active payment methods
              item.status === 'active' && (
                <div
                  key={item.id}
                  className={`account-item ${activeItem.name === item.name ? 'active' : ''}`}
                  onClick={() => handleItemClick(item.name, item.id)}
                >
                  <img
                    src={`https://nofa365.com/${item.icon}`}
                    alt={item.name}
                    className={`item-icon ${item.name === 'Bank' ? 'bank' : ''}`}
                  />
                  <p className="text-light">{item.name}</p>
                </div>
              )
            ))}
          </div>
        </div>

        <div className="card">
          <div className="card-title">
            <div className="card-title-l-line"></div>
            <h4>Withdrawals Channel</h4>
          </div>
          <div className="card-body">
            <div className="account-item active">
              <p className="text-light">Cash Out </p>
            </div>
          </div>
        </div>

        <div className="card">
          <div className="card-title">
            <div className="card-title-l-line"></div>
            <h4>Amount</h4>
          </div>
          <div className="card-body">
            {amounts.map((amount) => (
              <div
                key={amount}
                className={`account-item ${activeAmount === amount ? 'active' : ''}`}
                onClick={() => handleAmountClick(amount)}
              >
                <p className="text-light">{amount}</p>
              </div>
            ))}
          </div>
          <div className='amount-selected'>
            <span className='text-success'>৳</span>
            <p className='px-2'>{activeAmount}</p>
          </div>
        </div>
        <button className='btn' onClick={handleSubmit}>Submit</button>
      </div>
    </div>
  );
}

export default Funds;
