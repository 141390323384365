import React from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClock } from '@fortawesome/free-solid-svg-icons';

function PromotionGrid() {
    return (
        <div className="container my-2 promotion">
            <div className="row">
                {/* promotion::item-start */}
                <div className="col-lg-6 col-6">
                    <div className="promotion-card">
                        <img src="./thumbnail/promotion_01.jpg" alt="" />
                        <div className="line"></div>
                        <div className="promotion-body">
                            <div className="promotion-title">
                                <h1 className="text-white">Over ৳15 Crore Bonus</h1>
                                <span className="text-end">Long Time</span>
                            </div>
                            <p>Cricket Marathon Leaderboard</p>
                            <p><FontAwesomeIcon icon={faClock} /> 2024/05/30 22:00:00 ~ 2024/10/07 21:59:59</p>
                            <div className="my-2"></div>
                            <button className="btn-ui mx-2 px-4 py-2">Deposit</button>
                            <button className="btn btn-primary px-4 py-2">Details</button>
                        </div>
                    </div>
                </div>
                {/* promotion::item-start */}
                <div className="col-lg-6 col-6">
                    <div className="promotion-card">
                        <img src="./thumbnail/promotion_02.jpg" alt="" />
                        <div className="line"></div>
                        <div className="promotion-body">
                            <div className="promotion-title">
                                <h1 className="text-white">Over ৳15 Crore Bonus</h1>
                                <span className="text-end">Long Time</span>
                            </div>
                            <p>Cricket Marathon Leaderboard</p>
                            <p><FontAwesomeIcon icon={faClock} /> 2024/05/30 22:00:00 ~ 2024/10/07 21:59:59</p>
                            <div className="my-2"></div>
                            <button className="btn-ui mx-2 px-4 py-2">Deposit</button>
                            <button className="btn btn-primary px-4 py-2">Details</button>
                        </div>
                    </div>
                </div>
                {/* promotion::item-start */}
                <div className="col-lg-6 col-6">
                    <div className="promotion-card">
                        <img src="./thumbnail/promotion_03.jpg" alt="" />
                        <div className="line"></div>
                        <div className="promotion-body">
                            <div className="promotion-title">
                                <h1 className="text-white">Over ৳15 Crore Bonus</h1>
                                <span className="text-end">Long Time</span>
                            </div>
                            <p>Cricket Marathon Leaderboard</p>
                            <p><FontAwesomeIcon icon={faClock} /> 2024/05/30 22:00:00 ~ 2024/10/07 21:59:59</p>
                            <div className="my-2"></div>
                            <button className="btn-ui mx-2 px-4 py-2">Deposit</button>
                            <button className="btn btn-primary px-4 py-2">Details</button>
                        </div>
                    </div>
                </div>  
                 {/* promotion::item-start */}
                 <div className="col-lg-6 col-6">
                    <div className="promotion-card">
                        <img src="./thumbnail/promotion_04.jpg" alt="" />
                        <div className="line"></div>
                        <div className="promotion-body">
                            <div className="promotion-title">
                                <h1 className="text-white">Over ৳15 Crore Bonus</h1>
                                <span className="text-end">Long Time</span>
                            </div>
                            <p>Cricket Marathon Leaderboard</p>
                            <p><FontAwesomeIcon icon={faClock} /> 2024/05/30 22:00:00 ~ 2024/10/07 21:59:59</p>
                            <div className="my-2"></div>
                            <button className="btn-ui mx-2 px-4 py-2">Deposit</button>
                            <button className="btn btn-primary px-4 py-2">Details</button>
                        </div>
                    </div>
                </div>  
                 {/* promotion::item-start */}
                 <div className="col-lg-6 col-6">
                    <div className="promotion-card">
                        <img src="./thumbnail/promotion_05.jpg" alt="" />
                        <div className="line"></div>
                        <div className="promotion-body">
                            <div className="promotion-title">
                                <h1 className="text-white">Over ৳15 Crore Bonus</h1>
                                <span className="text-end">Long Time</span>
                            </div>
                            <p>Cricket Marathon Leaderboard</p>
                            <p><FontAwesomeIcon icon={faClock} /> 2024/05/30 22:00:00 ~ 2024/10/07 21:59:59</p>
                            <div className="my-2"></div>
                            <button className="btn-ui mx-2 px-4 py-2">Deposit</button>
                            <button className="btn btn-primary px-4 py-2">Details</button>
                        </div>
                    </div>
                </div>  
                 {/* promotion::item-start */}
                 <div className="col-lg-6 col-6">
                    <div className="promotion-card">
                        <img src="./thumbnail/promotion_06.jpg" alt="" />
                        <div className="line"></div>
                        <div className="promotion-body">
                            <div className="promotion-title">
                                <h1 className="text-white">Over ৳15 Crore Bonus</h1>
                                <span className="text-end">Long Time</span>
                            </div>
                            <p>Cricket Marathon Leaderboard</p>
                            <p><FontAwesomeIcon icon={faClock} /> 2024/05/30 22:00:00 ~ 2024/10/07 21:59:59</p>
                            <div className="my-2"></div>
                            <button className="btn-ui mx-2 px-4 py-2">Deposit</button>
                            <button className="btn btn-primary px-4 py-2">Details</button>
                        </div>
                    </div>
                </div>  
            </div>
        </div>
    );
}

export default PromotionGrid;
