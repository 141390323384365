import React from 'react';
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

function FavouriteSlider(){
    const settings = {
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false, 
       
      };
      

      return (
        <div className="slider-container">
          <div className='row my-3 align-items-center'>
            <div className='col-lg-6'>
                <div className='slide-header'></div>
                <Slider {...settings}>
            <div className="slide">
              <img src='./sliders/image_134715.jpg' alt="Cricket Match" className="slide-image" />
            </div>
            <div className="slide">
            <img src='./sliders/image_134713.jpg' alt="Cricket Match" className="slide-image" />
            </div>
            <div className="slide">
            <img src='./sliders/image_134714.jpg' alt="Cricket Match" className="slide-image" />
            </div>
          </Slider></div>
          <div className='col-lg-6'>
                <div className='slide-header'></div>
                <Slider {...settings}>
            
          
            <div className="slide">
            <img src='./sliders/image_134714.jpg' alt="Cricket Match" className="slide-image" />
            </div>
            <div className="slide">
              <img src='./sliders/image_134715.jpg' alt="Cricket Match" className="slide-image" />
            </div>
            <div className="slide">
            <img src='./sliders/image_134713.jpg' alt="Cricket Match" className="slide-image" />
            </div>
          </Slider></div>
         
          </div>
        </div>
      );

}
export default FavouriteSlider;