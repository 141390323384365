import React from 'react';
 function LiveChat(){

    return(
        <div className='live-chat'>
            <img src='./icon/icon-facebook-messenger.png' alt=''/>
        </div>
    );

 }
 export default LiveChat;