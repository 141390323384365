import React from "react";
import LoginModal from "../modals/Login";
import SignupModal from "../modals/Signup";

function NavMenu(){
        return(
            <div className="nav-menu">
                <ul className="navmenu">
                    
                    <li className="nav-item"><a href="/sports" className="nav-link"><img className="display-none mx-3" src="./icon/icon-sport.png" alt=""/>Sports</a></li>
                    <li className="nav-item"><a href="/casino" className="nav-link"><img className="display-none mx-3" src="./icon/icon-casino.png" alt=""/>Casino</a></li>
                    <li className="nav-item"><a href="/slot" className="nav-link"><img className="display-none mx-3" src="./icon/icon-slot.png" alt=""/>Slot</a></li>
                    <li className="nav-item"><a href="/table" className="nav-link"><img className="display-none mx-3" src="./icon/icon-table.png" alt=""/>Table</a></li>
                    <li className="nav-item"><a href="/poker" className="nav-link"><img className="display-none mx-3" src="./icon/icon-crash.png" alt=""/>Poker</a></li>
                    <li className="nav-item"><a href="/" className="nav-link"><img className="display-none mx-3" src="./icon/icon-fish.png" alt=""/>Fishing</a></li>
                    <li className="nav-item"><a href="/" className="nav-link"><img className="display-none mx-3" src="./icon/icon-arcade.png" alt=""/>Arcade</a></li>
                    <li className="nav-item"><a href="/" className="nav-link"><img className="display-none mx-3" src="./icon/icon-lottery.png" alt=""/>Lottery</a></li>
                    <li className="nav-item"><a href="/" className="nav-link"><img className="display-none mx-3" src="./icon/icon-promotion.png" alt=""/>Promotions</a></li>
                    <li className="nav-item"><a href="/" className="nav-link"><img className="display-none mx-3" src="./icon/icon-vip.png" alt=""/>VIP</a></li>
                </ul>
            </div>
        );
}
export default NavMenu;