import React from "react";

function Footer(){
    return(
        <div className="footer">
            <div className="footer-logos">
               <div className="row">
                    <div className="col-lg-3 col-12">
                    <div className="safe">
                        <h5 className="footer-logos-title">Responsible Gaming</h5>
                    <img src="./icon/safe1.png" alt=""/>
                    <img src="./icon/safe2.png" alt=""/>
                    <img src="./icon/safe3.png" alt=""/>
                </div>
                    </div>
            
            <div className="col-lg-9 col-12">
                <div className="payment-method">
                    <h5 className="footer-logos-title">Payemnt Method</h5>
                        <img src="./icon/pay16.png" alt=""/>
                        <img src="./icon/pay22.png" alt=""/>
                        <img src="./icon/pay33.png" alt=""/>
                        <img src="./icon/pay34.png" alt=""/>
                        <img src="./icon/pay45.png" alt=""/>
                        <img src="./icon/pay47.png" alt=""/>
                        <img src="./icon/pay59.png" alt=""/>
                        <img src="./icon/pay91.png" alt=""/>
                </div>
                </div>
               </div>
            </div>
        </div>
    );

}
export default Footer;