import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faMessage } from '@fortawesome/free-solid-svg-icons';
function formatDate(dateString) {
  const date = new Date(dateString);
  const options = {
    day: 'numeric',
    month: 'short',
    year: 'numeric',
    hour: '2-digit',
    minute: '2-digit'
  };
  const formattedDate = date.toLocaleDateString('en-US', options);
  return formattedDate;
}

function TransactionRecord() {
  const [transactionData, setTransactionData] = useState([]);
  const [activeTab, setActiveTab] = useState('Deposit');
  useEffect(() => {
    const fetchTransData = async () => {
        const token = localStorage.getItem('token');
        const remoteId = localStorage.getItem('remote_id');
        
        if (token && remoteId) {
            try {
                const response = await fetch(`https://nofa365.com/api/transaction/show?remote_id=${remoteId}`, {
                    method: 'GET',
                    headers: {
                        'Authorization': `Bearer ${token}`,
                    },
                });
  
                if (response.ok) {
                    const data = await response.json();
                    
                    setTransactionData(data);
                } else {
                    console.error('Failed to fetch user data with status:', response.status);
                    // Optionally, read and log the response text to see the error page content
                    const text = await response.text();
                    console.error('Error page content:', text);
                }
            } catch (error) {
                console.error('Error fetching user data:', error);
            }
        }
    };
  
    fetchTransData();
  }, []);
  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };
     
  
  return (
    <div className="funds betting-record">
      <div className="fund-header">
        <div className="fund-action-bar">
        <a className='nav-link' href='/account'>
            <FontAwesomeIcon icon={faArrowLeft} />
          </a>
          <h6>Transaction Record</h6>
          <div className="">
            <FontAwesomeIcon icon={faMessage} />
            <p>Help</p>
          </div>
        </div>
        <div className='fund-nav'>
        <ul className='p-3 mx-3'>
           
            <li
              className={`nav-item ${activeTab === 'Deposit' ? 'active' : ''}`}
              onClick={() => handleTabClick('Deposit')}
            >
              Settled
            </li>
            <li
              className={`nav-item ${activeTab === 'Withdraw' ? 'active' : ''}`}
              onClick={() => handleTabClick('Withdraw')}
            >
              Unettled
            </li>
        </ul>
       </div>
      </div>
      <div className={`fund-body ${activeTab.toLowerCase()} ${activeTab === 'Deposit' ? 'open' : ''}`}>
        <table>
            <thead>
               <tr>
               <th>Type</th>
                <th>Amount</th>
                <th>Status</th>
                <th>TRX Date</th>
               </tr>
            </thead>
            {transactionData.map((transaction, index) => {
                                if (transaction.action === 'deposit' || transaction.action === 'withdraw' || transaction.key === 1 || transaction.key === 2) {
                                    const statusKey = Number(transaction.key); // Convert key to a number
                                    return (
                                        <tr key={index}>
                                            <th scope="row">{transaction.action}</th>
                                            <td>{transaction.amount}</td>
                                            <td>
                                                {statusKey === 1 ? 'Success' : 
                                                statusKey === 0 ? 'Pending' : 
                                                statusKey === 2 ? 'Rejected' : 'Unknown'}
                                            </td>
                                            <td>{formatDate(transaction.created_at)}</td>
                                        </tr>
                                    );
                                } else {
                                    return null; // Render nothing for other action types
                                }
                            })}
           
        </table>
      

        
      
      </div>
      <div className={`fund-body ${activeTab.toLowerCase()} ${activeTab === 'Withdraw' ? 'open' : ''}`}>
        <table>
            <thead>
               <tr>
               <th>Type</th>
                <th>Amount</th>
                <th>Status</th>
                <th>TRX Date</th>
               </tr>
            </thead>
            {transactionData.map((transaction, index) => {
                                if (transaction.action === 'deposit' || transaction.action === 'withdraw' || transaction.key === 0) {
                                    const statusKey = Number(transaction.key); // Convert key to a number
                                    return (
                                        <tr key={index}>
                                            <th scope="row">{transaction.action}</th>
                                            <td>{transaction.amount}</td>
                                            <td>
                                                {statusKey === 1 ? 'Success' : 
                                                statusKey === 0 ? 'Pending' : 
                                                statusKey === 2 ? 'Rejected' : 'Unknown'}
                                            </td>
                                            <td>{formatDate(transaction.created_at)}</td>
                                        </tr>
                                    );
                                } else {
                                    return null; // Render nothing for other action types
                                }
                            })}
           
        </table>
      

        
      
      </div>
    </div>
  );
}

export default TransactionRecord;
