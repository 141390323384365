import React from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { faXmark } from '@fortawesome/free-solid-svg-icons';
import NavMenu from "./NavMenu";
import MobileNav from "./MobileNav";


function TapBar(){
    const closeTabBar = () => {
        const chatElement = document.querySelector('.tap-bar');
            chatElement.classList.remove('open');
        
      };
    return(
        <div className="tap-bar">

            <div className="d-flex">
            <div className="close">
            <FontAwesomeIcon onClick={closeTabBar} icon={faXmark} />
            
            </div>
           <MobileNav/>

           

            </div>
            
        </div>
    );
}
export default TapBar;