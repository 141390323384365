import React from "react";
import CasinoBanner from "../Banner/CasinoBanner";
import PokerList from "../common/PokerList";
import CrasherList from "../common/CrasherList";


function Crashers(){
   return(
    <div className="casino">
      <br/>
      <br/>
    <CasinoBanner/>
    <CrasherList/>
    </div>
   );
}
export default Crashers;