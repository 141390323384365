import React, { useState } from 'react';
import LoginModal from '../modals/Login';
import SignupModal from '../modals/Signup';
function BottomNav(){
    const [isLoggedIn, setIsLoggedIn] = useState(!!localStorage.getItem('token'));
    return(
        <div className="bottom-nav">
           
              
                {isLoggedIn ? (
          
           <ul>
            <li className="nav-item">
                    <a className="nav-link" href="/">
                    <img src="/icon/toolbar-icon-home.svg" alt=""/>
                    <p>Home</p></a>
                </li>
                <li className="nav-item">
                    <a className="nav-link" href="/">
                    <img src="/icon/toolbar-icon-promotion.svg" alt=""/>
                    <p>Promotions</p></a>
                </li>
                <li className="nav-item">
                    <a className="nav-link" href="/fund">
                    <img src="/icon/toolbar-icon-deposit.svg" alt=""/>
                    <p>Deposit</p></a>
                </li>
                <li className="nav-item">
                    <a className="nav-link" href="/account">
                    <img src="/icon/toolbar-icon-mine.svg" alt=""/>
                    <p>My Account</p></a>
                </li>
          
          </ul>
        ) : (
          <>
           <div className='not-login-bottom-nav d-flex'>
                <div className='lang-bottom-menu'>
                    <img src='./icon/mobile-bd.png' alt='Bangla'/>
                    <p>BDT <br/> English</p>
                </div>
               <div className='bottom-action'>
               <a className='login-bottom-menu' href='/login'>
              
                       Login
                  
                    </a>
                    <a className='signup-bottom-menu' href='/register'>
                    
                        Signup
                        
                    
                    </a>    
               </div>
           </div>
            
          </>
        )}
            
        </div>
    );
}
export default BottomNav;