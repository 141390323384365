import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import './App.css';
import './assets/css/TopNav.css';
import './assets/css/HeaderNav.css';
import './assets/css/NavMenu.css';
import './assets/css/Footer.css';
import './assets/css/Casino.css';
import './assets/css/Reponsive.css';
import './assets/css/Account.css';
import './assets/css/Fund.css';
import './assets/css/Promotion.css';
import Home from './components/Pages/Home';
import Footer from './components/layout/Footer';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import TopNav from './components/NavBar/TopNav';
import HeaderNav from './components/NavBar/HeaderNav';
import NavMenu from './components/NavBar/NavMenu';
import CasinoPage from './components/Pages/Casino';
import BottomNav from './components/NavBar/BottomNav';
import Funds from './components/modals/Funds';
import Account from './components/modals/Account';
import BettingRecord from './components/modals/BettingRecord';
import TransactionRecord from './components/modals/TransactionRecord';
import Profile from './components/modals/Profile';
import PasswordRest from './components/modals/PasswordRest';
import MyAccout from './components/Pages/MyAccount';
import TapBar from './components/NavBar/TapBar';
import Poker from './components/Pages/Poker';
import VideoPoker from './components/Pages/VideoPoker';
import Slot from './components/Pages/Slot';
import Table from './components/Pages/Table';
import GameLunch from './components/luncher/Game';
import ReferralProgram from './components/modals/ReferralProgram';
import Register from './components/Pages/Register';
import Login from './components/Pages/Login';
import Promotion from './components/Pages/Promotion';
import Crashers from './components/Pages/Crasher';
import Sports from './components/luncher/Sports';



function App() {
  return (
    <div className="App">
      <Router>
      <TopNav/>
        <HeaderNav/>
        <NavMenu/>
        <BottomNav/>
        <TapBar/>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/casino" element={ <CasinoPage/>} />
          <Route path="/fund" element={ <Funds/>} />
          <Route path="/crash" element={ <Crashers/>} />
          <Route path='/account' element={<Account/>}/>
          <Route path='/betting-record' element={<BettingRecord/>}/>
          <Route path='/transaction-record' element={<TransactionRecord/>}/>
          <Route path='/profile' element={<Profile/>}/>
          <Route path='/password-reset' element={<PasswordRest/>}/>
          <Route path='/poker' element={<Poker/>}/>
          <Route path='/video-poker' element={<VideoPoker/>}/>
          <Route path='/slot' element={<Slot/>}/>
          <Route path='/table' element={<Table/>}/>
          <Route path='/my-account' element={<MyAccout/>}/>
          <Route path='/game' element={<GameLunch/>}/>
          <Route path='/referral-program' element={<ReferralProgram/>}/>
          <Route path='/register' element={<Register/>}/>
          <Route path='/login' element={<Login/>}/>
          <Route path='/promotion' element={<Promotion/>}/>
          <Route path='/sports' element={<Sports/>}/>
          
        </Routes>
        <Footer/>
      </Router>
    </div>
  );
}

export default App;
