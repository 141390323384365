import React from "react";

function MoblileFavouriteSlide(){
    return(
        <div className="favourite-games content-title">
            <h2>Favourites</h2>
            <div className="favourite-games-items">
                <ul>
                    <li>
                    <img src='./sliders/image_134715.jpg' alt="Cricket Match" className="slide-image" />
                    </li>
                    <li>
                    <img src='./sliders/image_134713.jpg' alt="Cricket Match" className="slide-image" />
                    </li>
                    <li>
                    <img src='./sliders/image_134714.jpg' alt="Cricket Match" className="slide-image" />
                    </li>
                    <li>
                    <img src='./sliders/image_134715.jpg' alt="Cricket Match" className="slide-image" />
                    </li>
                    <li>
                    <img src='./sliders/image_134714.jpg' alt="Cricket Match" className="slide-image" />
                    </li>
                </ul>
            </div>
        </div>
    );

}
export default MoblileFavouriteSlide;