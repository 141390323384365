import React from "react";
import CasinoBanner from "../Banner/CasinoBanner";
import TableList from "../common/TableList";


function Table(){
   return(
    <div className="casino">
      <br/>
      <br/>
    <CasinoBanner/>
        <TableList/>
    </div>
   );
}
export default Table;