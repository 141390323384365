import React from "react";
import PromotionGrid from "../common/PromotionGrid";

function Promotion(){
    return(
        <div className="promotion">
            <div className="promotion_banner">
                <img src="./bg/promotion_banner.jpg" alt=""/>
            </div>
            <div className="promotion_grid">
                <PromotionGrid/>
            </div>
        </div>
    );
}
export default Promotion;