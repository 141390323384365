import React from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';

function CasinoBanner(){
    return(
        <><div className="casino-banner">
        <img src="./sliders/image_53685.jpg" alt=""/>
    </div>
   
    </>
        
    );
}
export default CasinoBanner;