import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope, faMobile } from '@fortawesome/free-solid-svg-icons';


function MainBalance(){
    
    const [isLoggedIn, setIsLoggedIn] = useState(!!localStorage.getItem('token'));
    const [balance, setBalance] = useState(localStorage.getItem('balance') || 0);
  
    const fetchBalance = async () => {
      const token = localStorage.getItem('token');
      if (token) {
        try {
          const response = await fetch('https://nofa365.com/api/balance', {
            method: 'POST',
            headers: {
              'Authorization': `Bearer ${token}`,
            },
          });
  
          if (response.ok) {
            const data = await response.json();
            setBalance(data.balance);
            localStorage.setItem('balance', data.balance);
          } else {
            console.error('Failed to fetch balance with status:', response.status);
            const text = await response.text();
            console.error('Error page content:', text);
          }
        } catch (error) {
          console.error('Error fetching balance:', error);
        }
      }
    };
  
    useEffect(() => {
      if (isLoggedIn) {
        fetchBalance();
        const interval = setInterval(fetchBalance, 10000);
        return () => clearInterval(interval);
      }
    }, [isLoggedIn]);

    return(
        <div className="main-balance">
                <div className="row justify-content-start">
                    <div className="col-lg-6 col-5 main-balance-left">
                        <p>Main Wallet </p>
                        <span>৳ {balance}</span>
                    </div>
                    <div className="col-lg-6 col-7   main-balance-right text-start row align-items-center">
                        <div className="col-lg-8 col-6">
                        <p>Security Level</p>
                        <span className="text-success">Verified</span>
                        </div>
                        {/* <div className="col-lg-4 col-6">
                      
                        <FontAwesomeIcon icon={faEnvelope}  className="unverified-icons" />
                        <FontAwesomeIcon icon={faMobile} style={{ padding: '8px 10px' }}  className="unverified-icons" />
                        </div> */}
                    </div>
                </div>
        </div>
    );
}
export default MainBalance;