import React, { useState } from 'react';
import { Modal, Button, Form, InputGroup } from 'react-bootstrap';
import { FaUser, FaLock, FaArrowLeft } from 'react-icons/fa';
import { AiFillEye, AiFillEyeInvisible } from 'react-icons/ai';

function LoginModal() {
  const [show, setShow] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const togglePasswordVisibility = () => setShowPassword(!showPassword);

  const handleLogin = async (event) => {
    event.preventDefault(); // Prevent form submission from reloading the page

    try {
      const response = await fetch('https://nofa365.com/api/login', {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ username, password }),
      });

      const data = await response.json();

      if (response.ok) {
        console.log(data);
        localStorage.setItem('token', data.token);
        localStorage.setItem('sessionid', data.response.response.sessionid);
        localStorage.setItem('remote_id', data.response.response.id);
        localStorage.setItem('username', data.response.response.username);

        // Redirect to another page or update the state to indicate successful login
        // For example:
        window.location.href = '/'; // Redirect to a dashboard page
      } else {
        console.log(data.message || 'An unexpected error occurred');  // Assuming the server sends an error message in the response
      }
    } catch (error) {
      console.error('Error during login:', error);
    }
  };

  return (
    <>
      <li onClick={handleShow} className="nav-item bottom-nav-item">
        <div className="nav-link">Login</div>
      </li>

      <Modal show={show} onHide={handleClose} centered>
        <Modal.Header closeButton className='custom-close'>
          <Modal.Title><FaArrowLeft onClick={handleClose} className='display-none' /> Welcome to Baji</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <img className='w-100 p-5 display-none' src='./logos/logo.png' alt="Logo" />
          <Form onSubmit={handleLogin}>
            <Form.Group controlId="formBasicUsername">
              <Form.Label>Username</Form.Label>
              <InputGroup className="custom-input-group">
                <InputGroup.Text>
                  <FaUser />
                </InputGroup.Text>
                <Form.Control 
                  type="text" 
                  placeholder="Enter username" 
                  value={username} 
                  onChange={(e) => setUsername(e.target.value)} 
                />
              </InputGroup>
            </Form.Group>

            <Form.Group controlId="formBasicPassword">
              <Form.Label>Password</Form.Label>
              <InputGroup className="custom-input-group">
                <InputGroup.Text>
                  <FaLock />
                </InputGroup.Text>
                <Form.Control
                  type={showPassword ? 'text' : 'password'}
                  placeholder="Password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
                <InputGroup.Text onClick={togglePasswordVisibility}>
                  {showPassword ? <AiFillEyeInvisible /> : <AiFillEye />}
                </InputGroup.Text>
              </InputGroup>
            </Form.Group>

            <Form.Group controlId="formBasicCheckbox">
              <Form.Check type="checkbox" label="Remember me" />
            </Form.Group>
            <Button
              variant="primary"
              type="submit"
              className="w-100 gradient-button"
            >
              Login
            </Button>
          </Form>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default LoginModal;
