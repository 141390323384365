import React, { useState, useEffect } from 'react';
import { Tab, Nav } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser, faPhone, faEnvelope, faBirthdayCake, faInfo, faStar} from '@fortawesome/free-solid-svg-icons';
import MainBalance from '../common/MainBalance';

function formatDate(dateString) {
    const date = new Date(dateString);
    const options = {
      day: 'numeric',
      month: 'short',
      year: 'numeric',
      hour: '2-digit',
      minute: '2-digit'
    };
    const formattedDate = date.toLocaleDateString('en-US', options);
    return formattedDate;
  }

function MyAccount(){
    const [activeKey, setActiveKey] = useState('wallet');
    const [activeItem, setActiveItem] = useState('Bkash');
    const [activeAmount, setActiveAmount] = useState(null);
    const [activeTab, setActiveTab] = useState('Deposit');
    const [transactionData, setTransactionData] = useState([]);
  useEffect(() => {
    const fetchTransData = async () => {
        const token = localStorage.getItem('token');
        const remoteId = localStorage.getItem('remote_id');
        
        if (token && remoteId) {
            try {
                const response = await fetch(`https://nofa365.com/api/api/transaction/show?remote_id=${remoteId}`, {
                    method: 'GET',
                    headers: {
                        'Authorization': `Bearer ${token}`,
                    },
                });
  
                if (response.ok) {
                    const data = await response.json();
                    console.log(data);
                    setTransactionData(data);
                } else {
                    console.error('Failed to fetch user data with status:', response.status);
                    // Optionally, read and log the response text to see the error page content
                    const text = await response.text();
                    console.error('Error page content:', text);
                }
            } catch (error) {
                console.error('Error fetching user data:', error);
            }
        }
    };
  
    fetchTransData();
  }, []);
     
  
    const items = [
      { name: 'Bkash', icon: './icon/bkash.png' },
      { name: 'Nagad', icon: './icon/nagad.png' },
      { name: 'USDT TRC20', icon: './icon/trc20.svg' },
      { name: 'BTC', icon: './icon/btc.svg' },
      { name: 'ETH', icon: './icon/eth.svg' },
      { name: 'Bank', icon: './icon/bank-card.png' },
    ];
    const amounts = ['100', '200', '500', '1,000', '2,000', '5,000'];
    const handleItemClick = (name) => {
        setActiveItem(name);
      };
    
      const handleAmountClick = (amount) => {
        const numericAmount = parseInt(amount.replace(/,/g, ''), 10); // Convert the clicked amount to a number
        setActiveAmount(prevAmount => {
          if (prevAmount !== null) {
            const numericPrevAmount = parseInt(prevAmount.replace(/,/g, ''), 10); // Convert the previous amount to a number
            return (numericPrevAmount + numericAmount).toLocaleString(); // Add and format the result
          } else {
            return numericAmount.toLocaleString(); // Format the clicked amount
          }
        });
      };
    return(
        <div className="my-account">
                <Tab.Container id="left-tabs-example" defaultActiveKey="wallet">

                    <div className='row mx-5 my-3'>
                        <div className='col-lg-3 my-account-nav'>
                            <div className='my-account-nav-header'>
                                <img src='./icon/normal.png' />
                                <p className='text-light'>emonhossaincse</p>
                                <hr/>
                                <ul className='d-flex justify-content-around main-nav-ac'>
                                    <li className='nav-item'><a href=''>
                                        <img src='./icon/icon-deposit.png'/>
                                        <p className='text-light'>Deposit</p>
                                        </a></li>
                                        <li className='nav-item'><a href=''>
                                        <img  src='./icon/icon-withdrawal.png'/>
                                        <p className='text-light'>Withdrawal</p>
                                        </a></li>
                                </ul>
                                <hr/>
                                <h6 className='text-light text-start'>Funds</h6>
                            </div>
                        <Nav variant="pills" className="flex-row account-main-nav">
                        <Nav.Item>
                        <Nav.Link eventKey="betting-record" onClick={() => setActiveKey('betting-record')}> <img src='./icon/bet-record.svg' alt=''/> <span className='account-nav-link'>Betting Record</span></Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                        <Nav.Link eventKey="wallet" onClick={() => setActiveKey('wallet')}> <img src='./icon/wallet.svg' alt=''/> <span className='account-nav-link'>My Wallet</span></Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                        <Nav.Link eventKey="turnover" onClick={() => setActiveKey('turnover')}> <img src='./icon/turnover.svg' alt=''/> <span className='account-nav-link'> Turnover</span></Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                        <Nav.Link eventKey="vip" onClick={() => setActiveKey('vip')}> <img src='./icon/vip.svg' alt=''/> <span className='account-nav-link'> VIP</span></Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                        <Nav.Link eventKey="transaction" onClick={() => setActiveKey('transaction')}> <img src='./icon/transaction-record.svg' alt=''/> <span className='account-nav-link'> Transaction Record</span></Nav.Link>
                        </Nav.Item>
                        <hr/>
                        <h6 className='text-light'>Profile</h6>
                        <Nav.Item>
                        <Nav.Link eventKey="profile" onClick={() => setActiveKey('profile')}> <img src='./icon/member.svg' alt=''/> <span className='account-nav-link'> Personal Info</span></Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                        <Nav.Link eventKey="reset-pass" onClick={() => setActiveKey('reset-pass')}> <img src='./icon/password.svg' alt=''/> <span className='account-nav-link'> Reset Password</span></Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                        <Nav.Link eventKey="inbox" onClick={() => setActiveKey('inbox')}> <img src='./icon/mail.svg' alt=''/> <span className='account-nav-link'>Inbox</span></Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                        <Nav.Link eventKey="referral" onClick={() => setActiveKey('referral')}> <img src='./icon/friend.svg' alt=''/> <span className='account-nav-link'> Referral </span></Nav.Link>
                        </Nav.Item>
                        
                    </Nav>
                        </div>
            <div className='col-lg-9 '>

                       <div className='account-content'>
                       <Tab.Content>
                        <Tab.Pane eventKey="betting-record" active={activeKey === 'betting-record' } className='p-3'>
                            <MainBalance/>
                        <div className='card-content my-2'>
                            <div className='content-title'>
                               <span></span> <h4 className='text-success text-start'>Betting Records</h4>
                                <hr/>
                            </div>
                            <table className='content-table'>
                            <thead>
                                <tr>
                                <th>Platform</th>
                                <th>Game Type</th>
                                <th>Turnover</th>
                                <th>Profit/Loss</th>
                                <th>Settle Date</th>
                                </tr>
                            </thead>
                            <tbody>
                            {transactionData.map((transaction, index) => {
                                if (transaction.action === 'debit' || transaction.action === 'credit') {
                                    const statusKey = Number(transaction.gameplay_final); // Convert key to a number
                                    return (
                                        <tr key={index}>
                                            <th scope="row">{transaction.provider}</th>
                                            <td>{transaction.game_id_hash}</td>
                                            <td></td>
                                            <td>
                                                {statusKey === 1 ? 'Success' : 
                                                statusKey === 0 ? 'Pending' : 
                                                statusKey === 2 ? 'Rejected' : 'Unknown'}
                                            </td>
                                            <td>{formatDate(transaction.created_at)}</td>
                                        </tr>
                                    );
                                } else {
                                    return null; // Render nothing for other action types
                                }
                            })}
                            </tbody>

                            </table>
                        </div>
                        
                        
                        </Tab.Pane>
                        <Tab.Pane eventKey="transaction" active={activeKey === 'transaction' } className='p-3'>
                        <div className='card-content'>
                            <div className='content-title'>
                                <h4 className='text-success text-start'>Transactions Records</h4>
                                <hr/>
                            </div>
                            <table className='content-table'>
                            <thead>
                                <tr>
                                <th>Type</th>
                                <th>Amount</th>
                                <th>Status</th>
                                <th>TRX Date</th>
                                </tr>
                            </thead>
                            <tbody>
                            {transactionData.map((transaction, index) => {
                                if (transaction.action === 'deposit' || transaction.action === 'withdraw') {
                                    const statusKey = Number(transaction.key); // Convert key to a number
                                    return (
                                        <tr key={index}>
                                            <th scope="row">{transaction.action}</th>
                                            <td>{transaction.amount}</td>
                                            <td>
                                                {statusKey === 1 ? 'Success' : 
                                                statusKey === 0 ? 'Pending' : 
                                                statusKey === 2 ? 'Rejected' : 'Unknown'}
                                            </td>
                                            <td>{formatDate(transaction.created_at)}</td>
                                        </tr>
                                    );
                                } else {
                                    return null; // Render nothing for other action types
                                }
                            })}
                            </tbody>
                            </table>
                        </div>
                        
                        
                        </Tab.Pane>
                        <Tab.Pane eventKey="wallet" active={activeKey === 'wallet'}>
                            <MainBalance/>
                         
                                <div className='wallets my-2'>
                                   <div className='row w-100'>
                                   <div className='col-lg-9'>
                                    <div className='card-content'>
                            <div className='content-title'>
                               <span></span> <h4 className='text-success text-start'>Funds</h4>
                                <hr/>
                            </div>
                          <p className='text-start text-white'>Payment Method</p>

                          <div className='d-flex payment-methods'>
                          {items.map((item) => (
                            <div 
                            key={item.name}
                            className={`payment-method-item ${activeItem === item.name ? 'active' : ''}`}
                            onClick={() => handleItemClick(item.name)}
                            >
                                
                                <img src={item.icon} alt={item.name} />
                                <p>{item.name}</p>
                            </div>
                               ))}

                          </div>

                          <p className='text-start text-white'>Payment Type</p>
                          <p className='payment-system text-start'>{activeItem} Payment</p>
                          <div className='payment-card p-2'>
                            <p>Deposit Channel</p>

                            <div className='text-start'>
                                <span className='channel active'>Cash Out</span>
                                <span className='channel'>Send Money</span>
                                </div>
                                <p className='my-2'>Amount   <span className='px-5 mx-5 text-warning'>৳100 - 25000</span></p>

                                <div className='d-flex'>
                                {amounts.map((amount) => (
                                    <div 
                                    key={amount}
                                    className={`selecetd-amount ${activeAmount === amount ? 'active' : ''}`}
                                    onClick={() => handleAmountClick(amount)}
                                    >
                                        {amount}
                                    </div>
                                    ))}
                                </div>
                                <div className='password-notice text-start'>
                                <div className='d-flex algin-items-center'>
                                <FontAwesomeIcon icon={faInfo} /> <span className='px-2'>Dear all member, to speed up your deposit process, kindly follow these steps :</span>

                                </div>
                                <ul className='password-reset-lists'>
                                    <li>verify the phone number you used for deposit.</li>
                                    <li>Input the correct reference number.</li>
                                    <li>Process only selected amount.</li>
                                    <li>Attach the successful Slip.</li>
                                    <li>Reminder:Do not save the phone number displayed on Baji website on your device to avoid losing money.</li>
                                    
                                </ul>
                            </div>
                               <div className='text-start py-3'>
                               <input type='text' value={activeAmount} name='amount' className='amount-input'/>
                               <button className='btn-ui'>Deposit</button>
                               </div>

                          </div>
                            
                               
                            
                          
                            
                            
                           
                        </div>
                                    </div>
                                    <div className='col-lg-3 deposit-record'>
                                        <p  className='text-start'>Deposit Records</p>
                                        <img src='./icon/no-value.svg' alt=''/>
                                    </div>
                                   </div>
                                </div>
                        </Tab.Pane>
                       
                        <Tab.Pane eventKey="bet" active={activeKey === 'bet'}>
                         
   
                        </Tab.Pane>
                        <Tab.Pane eventKey="reset-pass" active={activeKey === 'reset-pass'}>
                        <MainBalance/>
                        <div className='card-content my-2'>
                            <div className='content-title'>
                               <span></span> <h4 className='text-success text-start'>Reset Password</h4>
                                <hr/>
                            </div>
                            <div className='password-notice text-start'>
                                <div className='d-flex algin-items-center'>
                                <FontAwesomeIcon icon={faInfo} /> <span className='px-2'>Password requirements : </span>

                                </div>
                                <ul className='password-reset-lists'>
                                    <li>must be 6-20 characters</li>
                                    <li>must contain 1 uppercase alphbet {'(A-Z)'} at least</li>
                                    <li>must contain 1 lowercase alphbet {'(a-z)'} at least</li>
                                    <li>must contain 1 number {'(0-9)'} at least</li>
                                    <li>allow special characters{'(@$!%*#)'}</li>
                                    
                                </ul>
                            </div>
                            
                          
                            <div className='row my-3 reset-password-groups'>
                                <div className='col-lg-6'>
                                <div className='input-groups'>
                                <label>Current password</label>
                                <input type='password' name='currentpassword'/>
                            </div>
                            </div>
                            <div className='col-lg-6'>
                               
                            </div>
                            
                                
                                <div className='col-lg-6'>
                                <div className='input-groups'>
                                <label>New password</label>
                                <input type='password' name='newpassword'/>
                            </div>
                                </div>
                                <div className='col-lg-6'>
                                    <div className='input-groups'>
                                    <label>Re-Type password</label>
                                    <input type='password' name='retypepassword'/>
                            </div>
                                </div>
                                
                                
                            </div>
                            <button className='reset-password-btn btn-ui'>Confirm</button>
                           
                        </div>
                         
   
                         </Tab.Pane>
                       
                       
                        <Tab.Pane eventKey="transaction" active={activeKey === 'transaction'}>
                        
                        </Tab.Pane>
                        
                        <Tab.Pane eventKey="vip" active={activeKey === 'vip'}>
                            <MainBalance/>
                                 <div className='vip-club my-2'>
                                 <div className='card-content'>
                            <div className='content-title'>
                               <span></span> <h4 className='text-success text-start'>VIP</h4>
                                <hr/>
                            </div>
                            <div className='row'>
                                <div className='d-flex vip-card'>

                                <img src='./icon/normal.png' alt='VIP ICON'/>

                                    <div className='vip-users'>
                                        
                                        <span>VIP LEVEL</span>
                                        <h4>NORMAL</h4>
                                    </div>
                                    
                                </div>
                                
                                <div className='vip-info'>
                                        <div className='vip-star'><FontAwesomeIcon icon={faStar}/> <span>0</span></div>
                                        <p><FontAwesomeIcon icon={faInfo}/>View VIP Details</p>
                                </div>
                            </div>
                            </div>

                                 </div>
                        </Tab.Pane>
                         
                        <Tab.Pane eventKey="profile" active={activeKey === 'profile'}>
                                <MainBalance/>
                                <div className='recent-activity'>
                                    <div className='row'>
                                        <div className='col-lg-3 boder-right'>
                                            <p>Last Login Time</p>
                                            <span>2024/07/08 19:44:27</span>
                                        </div>
                                        <div className='col-lg-3 boder-right'>
                                            <p>Last Deposit Time</p>
                                            <span>2024/07/08 19:44:27</span>
                                        </div>
                                        <div className='col-lg-3 boder-right'>
                                            <p>Last Withdrawal Time</p>
                                            <span>2024/07/08 19:44:27</span>
                                        </div>
                                        <div className='col-lg-3 '>
                                            <p>Date Registered</p>
                                            <span>2024/07/08 19:44:27</span>
                                        </div>
                                    </div>
                                </div>
                                <div className='user-information'>
                                    <div className='row'>
                                        <div className='col-lg-3'>
                                            <div className='user-info-lists d-flex align-items-center'>
                                                <FontAwesomeIcon icon={faUser} className='users-info-icons'/>
                                                <span className='text-white px-2'> emonhossaincse</span>
                                            </div>
                                        </div>
                                        <div className='col-lg-3'>
                                            <div className='user-info-lists d-flex align-items-center'>
                                                <FontAwesomeIcon icon={faPhone} className='users-info-icons'/>
                                                <span className='text-white px-2'> +8801799872359</span>
                                            </div>
                                        </div>
                                        <div className='col-lg-3'>
                                            <div className='user-info-lists d-flex align-items-center'>
                                                <FontAwesomeIcon icon={faEnvelope} className='users-info-icons'/>
                                                <span className='text-white px-2'> hossainn@gmail.com</span>
                                            </div>
                                        </div>
                                        <div className='col-lg-3'>
                                            <div className='user-info-lists d-flex align-items-center'>
                                                <FontAwesomeIcon icon={faBirthdayCake} className='users-info-icons'/>
                                                <span className='text-white px-2 birth-edit'> Edit Brithday</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                        </Tab.Pane>
                       
                       
                    </Tab.Content>
                       </div>
                        </div>
                    </div>

     
    
    </Tab.Container>
        </div>
    )
}
export default MyAccount;