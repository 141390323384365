import React from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWhatsapp, faFacebook } from '@fortawesome/free-brands-svg-icons';  
import { faEnvelope, faMessage } from '@fortawesome/free-solid-svg-icons';




function TopNav(){
    return(
        <div className="top-nav algin">
            <div className="top-nav-l">
                <div className="time-zone mx-2">(GMT+6) 02:29:53</div>
                <div className="currency-lan"><img src="./icon/bd.png" alt=""/>৳ BDT English</div>
            </div>

            <div className="top-nav-r">
                <ul>
                    <li className="nav-item"><a className="nav-link" href="/"><FontAwesomeIcon icon={faWhatsapp} /> WhatsApp</a></li>
                    <li className="nav-item"><a className="nav-link" href="/"><FontAwesomeIcon icon={faEnvelope} /> Email</a></li>
                    <li className="nav-item"><a className="nav-link" href="/"><FontAwesomeIcon icon={faFacebook} /> Facebook</a></li>
                    <li className="nav-item"><a className="nav-link" href="/"><FontAwesomeIcon icon={faMessage} /> Forum</a></li>
                </ul>
            </div>

        </div>
    );
}
export default TopNav;