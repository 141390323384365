import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faMessage } from '@fortawesome/free-solid-svg-icons';
function Profile(){
  const [userData, setUserData] = useState(null);
    useEffect(() => {
        const fetchUserData = async () => {
            const token = localStorage.getItem('token');
            const remoteId = localStorage.getItem('remote_id');
            
            if (token && remoteId) {
                try {
                    const response = await fetch(`https://nofa365.com/api/user/show?remote_id=${remoteId}`, {
                        method: 'GET',
                        headers: {
                            'Authorization': `Bearer ${token}`,
                        },
                    });
    
                    if (response.ok) {
                        const data = await response.json();
                        
                        setUserData(data);
                        
                    } else {
                        console.error('Failed to fetch user data with status:', response.status);
                        // Optionally, read and log the response text to see the error page content
                        const text = await response.text();
                        console.error('Error page content:', text);
                    }
                } catch (error) {
                    console.error('Error fetching user data:', error);
                }
            }
        };
    
        fetchUserData();
    }, []);
    return(
        <div className="funds profile">
        <div className="fund-header">
          <div className="fund-action-bar">
          <a className='nav-link' href='/account'>
            <FontAwesomeIcon icon={faArrowLeft} />
          </a>
            <h6>Personal Info</h6>
            <div className="">
              <FontAwesomeIcon icon={faMessage} />
              <p>Help</p>
            </div>
          </div>
         
        </div>
        {userData ? (
        <div className="fund-body">
            <img className="profile-bg" src="./bg/vip-card-bg-1.jpg" alt=''/>
           
                           <p className="text-success text-center">{userData.username}<span className="badge">Normal</span></p>
                        
            

            <div className="profile-info">
                <div className="input-groups">
                <label>Full Name</label>
                
                <p className="text-warning py-3">{userData.name}</p>
                </div>
                <div className="input-groups">
                <label>Phone Number</label>
                    <p className="text-success py-3">{userData.phone}</p>
                </div>
                <div className="input-groups">
                <label>Email Address</label>
                <p className="text-success py-3">{userData.email}</p>
                </div>
                <div className="input-groups">
                <label>Birthday</label>
                <p className="text-danger py-3">MM/DD/YYYY</p>
                </div>
               
            </div>
  

        
        </div>
        ) : (
          <p>Loading user data...</p>
        )}
      </div>
    );
}
export default Profile;