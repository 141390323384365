import React, { useState, useEffect } from "react";
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';


function CasinoList() {
    const [games, setGames] = useState([]);
    const [filteredGames, setFilteredGames] = useState([]);
    const [searchQuery, setSearchQuery] = useState("");
    const [isMobile, setIsMobile] = useState(false);
    const navigate = useNavigate();
    const [error, setError] = useState(null);

    useEffect(() => {
        fetch("https://nofa365.com/api/games?type=live-casino-table&category=evolution")
            .then(response => response.json())
            .then(data => {
                setGames(data.games);
                setFilteredGames(data.games);
            })
            .catch(error => {
                console.error('Error fetching games:', error);
            });

        const isMobileDevice = window.innerWidth <= 768;
        setIsMobile(isMobileDevice);
        console.log('Is Mobile:', isMobileDevice);
    }, []);

    const handleSearch = (event) => {
        const query = event.target.value.toLowerCase();
        setSearchQuery(query);

        const filtered = games.filter(game => game.name.toLowerCase().includes(query));
        setFilteredGames(filtered);
    };

    const handleGameClick = async (gameId) => {
        try {
            const token = localStorage.getItem('token');

            if (!token) {
                setError('User not logged in');
                console.log('User not logged in');
                return;
            }

            const response = await fetch('https://nofa365.com/api/get-game-direct', {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                },
                body: JSON.stringify({
                    game_id: gameId,
                    lang: 'en',
                    play_for_fun: false,
                    home_url: 'https://www.24baji.live',
                }),
            });

            const data = await response.json();

            if (response.ok) {
                console.log(data);
                navigate('/game', { state: { iframeUrl: data.response.response.url } });
                
                
            } else {
                setError(data.message || 'An unexpected error occurred');
            }
        } catch (error) {
            console.error('Error fetching game details:', error);
            setError('An unexpected error occurred');
        }
    };

    return (
        <div className="casino">
            <span className="text-danger">{error}</span>
            <div className="search-bar">
                <FontAwesomeIcon icon={faSearch} className="search-icon" />
                <input
                    placeholder="Search"
                    type="search"
                    name="search"
                    value={searchQuery}
                    onChange={handleSearch}
                />
            </div>
            <div className="row m-2">
                {filteredGames.map(game => (
                    (!isMobile && !game.mobile) || (isMobile && game.mobile) ? (
                        <div key={game.id} className="col-lg-2 col-6 my-2 casino-list">
                            <div className="casion-item">
                            <img src={game.image_filled} alt={game.name} />
                            <div className="game-title">
                            {/* <p>{game.name}</p> */}
                            </div>
                            <div className="casino-list-hover">
                                <button onClick={() => handleGameClick(game.id)}>Play Now</button>
                                
                            </div>
                            </div>
                        </div>
                    ) : null
                ))}
            </div>
        </div>
    );
}

export default CasinoList;
